





















































import { Component, Vue } from "vue-property-decorator";
import { Guild, GuildItem } from "@/types";

@Component
export default class GuildDashboardDialog extends Vue {
  dialog = false;
  radioModel = 0;

  get selectMessage(): string {
    if (this.currentGuild === null) {
      return "Select a server";
    } else {
      return this.currentGuild.name;
    }
  }

  get currentGuild(): Guild | null {
    return this.$store.getters.getCurrentGuild;
  }

  get currentGuildID(): number {
    return this.currentGuild ? this.currentGuild.id : 0;
  }

  get guilds(): Array<Guild> {
    return this.$store.getters.getGuilds;
  }

  get guildItems(): Array<GuildItem> {
    const items = [];
    for (let guild of this.guilds) {
      items.push({ label: guild.name, value: guild.id });
    }
    return items;
  }

  close() {
    this.dialog = false;
    this.radioModel = this.currentGuildID;
  }

  guildSelected(id: number): void {
    this.dialog = false;
    this.$store.commit("setCurrentGuildID", id);
  }
}
