







import { Component, Vue } from "vue-property-decorator";
import GuildDashboardDialog from "@/components/GuildDashboardDialog.vue";

@Component({
  components: {
    GuildDashboardDialog,
  },
})
export default class GuildDashboard extends Vue {
  get userLinked() {
    return this.$store.getters.isUserLinked;
  }
}
